import React from "react";

import Header from "@layouts/Header";
import Info from "@sections/Info";
import Integrations from "@sections/Integrations";
import Experts from "@sections/Experts";
import ContactForm from "@containers/ContactForm";

import "./Service.styles.scss";

const Service = ({ data }) => {
  const {
    header = {},
    reviews = {},
    integrations = {},
    contact = {},
    experts = {},
  } = data;
  return (
    <div className="service">
      <Header {...header} />
      <Info {...reviews} />
      <Integrations {...integrations} />
      <ContactForm {...contact} />
      <Experts {...experts} />
    </div>
  );
};

export default Service;
