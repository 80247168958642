import React from "react";
import Button from "@components/Button";
import Image from "@components/Image";
import "./Integrations.styles.scss";

const Integrations = ({ title, description, images = [] }) => {
  return (
    <section className="integrations container">
      <div className="integrations__inner">
        <div className="integrations__box">
          <div className="integrations__box__inner">
            <h2 className="title title-2">{title}</h2>
            <p className="description description-2">{description}</p>
            <Button type="primary">Button</Button>
          </div>
        </div>
        <div className="integrations__images">
          {images.map(image => {
            return <Image data={image?.image} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Integrations;
